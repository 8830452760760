<template>
  <div style="font-family: pingfang">
    <combo-head />
    <div style="padding: 10px">套餐推荐</div>
    <div
      v-for="(item, key) in comboList"
      :key="key"
      class="content"
      @click="comboChoose(item)"
    >
      <van-row :class="comboActive == item.ccbId ? 'active' : ''">
        <van-col span="18" style="padding: 10px">
          <span class="custom-title">{{ item.ccbName }}</span
          ><br />
          <van-tag type="danger" style="margin: 3px"
            >到账{{ item.arrivalAmount }}</van-tag
          >
        </van-col>
        <van-col span="6" class="right-content">
          <div>
            <span>￥{{ item.currentPrice }}</span>
          </div>
        </van-col>
      </van-row>
    </div>
    <div class="tips">
      <h4>温馨提示</h4>
      <p style="font-size: 10px;">1.本卡只在本公众号充值有效</p>
      <p style="font-size: 10px;">2.物联卡需充值和实名后方可使用</p>
      <p style="font-size: 10px;">3.网络延迟导致异常情况请尝试重新进入</p>
      <p style="font-size: 10px;">4.如遇问题请联系公众号客服处理</p>
    </div>
    <div style="height: 40px" />
    <common-footer />
    <van-popup
      v-model:show="show"
      position="bottom"
      :style="{ height: '70%' }"
    >
      <div style="position:sticky;top:0;background-color: white;z-index: 99;">
        <div style="height:10px;"></div>
        <van-card
          :tag="itemTag"
          :price="itemPrice"
          :desc="itemDesc"
          :title="itemTitle"
          thumb="/images/mobile.png"
          :origin-price="itemOriginPrice"
        />
      </div>
      <van-radio-group v-model="payType">
        <van-cell-group>
          <van-cell
            title="微信支付"
            icon="chat-o"
            clickable
            @click="payType = '1'"
          >
            <template #right-icon>
              <van-radio name="1" icon="passed" />
            </template>
          </van-cell>
          <!-- <van-cell
            title="余额支付"
            icon="paid"
            clickable
            @click="payType = '2'"
          >
            <template #right-icon>
              <van-radio name="2" />
            </template>
          </van-cell> -->
        </van-cell-group>
      </van-radio-group>
      <!-- <van-field
        v-model="packTime"
        readonly
        clickable
        name="datetimePicker"
        label="套餐生效日期"
        placeholder="点击选择时间"
        input-align="right"
        @click="showPicker = true"
      />
      <van-popup v-model:show="showPicker" position="bottom">
        <van-datetime-picker
          title="选择年月"
          type="year-month"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup> -->
      <van-submit-bar
        :price="payPrice"
        button-text="确认支付"
        tip='请仔细确认套餐信息'
        tip-icon="info-o"
        :loading="loading"
        decimal-length="2"
        @submit="buyCombo"
      />
    </van-popup>
  </div>
</template>

<script>
import { getCombos, createOrder } from "@/api/card";
import wechatUtil from "@/utils/wechatUtil";
import { Dialog,Notify } from "vant";
import "@/assets/combo.css";
import comboHead from "@/views/combos/header.vue";
import commonFooter from "@/views/common/footer.vue";

export default {
  name: "Balance",
  components: {
    comboHead,
    commonFooter,
  },
  data() {
    return {
      checked: true,
      comboList: [],
      comboActive: 0,
      show: false,
      payType: "1",
      payPrice: 0,
      itemPrice: 0,
      itemOriginPrice: 0,
      itemTag: "推荐",
      itemTitle: "商品标题",
      itemDesc: "描述信息",
      showPicker: false,
      packTime: null,
      goods: null,
      loading:false,
    };
  },
  created() {
    this.getComboList();
  },
  watch:{
    'show':'downShow'
  },
  methods: {
    downShow(newer,older){
      if(!newer){
        this.loading = false
      }
    },
    onConfirm(time) {
      this.packTime = this.formatDate(time);
      this.showPicker = false;
    },
    formatDate(inputTime) {
      var date = new Date(inputTime);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      return y + "-" + m;
    },
    getComboList() {
      getCombos().then(
        (res) => {
          this.comboList = res[2];
        }
      );
    },
    comboChoose(item) {
      this.comboActive = item.ccbId;
      this.goods = item;
      this.payPrice = item.currentPrice * 100;
      this.itemPrice = item.currentPrice;
      this.itemOriginPrice = item.originalPrice;
      this.itemTitle = item.title;
      // this.itemDesc = item.remark
      this.show = true;
    },
    buyCombo() {
      this.loading = true;
      let user = JSON.parse(localStorage.getItem('user-info'))
      createOrder(
        this.$store.getters.iccId,
        this.goods.groupId,
        this.goods.ccbId,
        this.payType,
        user.officialId,
        user.openid
      ).then((res) => {
        console.log('支付',res);
        wechatUtil.init(["chooseWXPay"]).then((wx) => {
          this.loading = false;
          wx.chooseWXPay({
            timestamp: res.timestamp,
            nonceStr: res.nonce_str,
            package: res.prepay_id,
            signType: res.signType,
            paySign: res.sign,
            success: function () {
                // 成功通知
                Notify({ type: 'success', message: '支付成功' });

            },
            error: function () {
                // 危险通知
                Notify({ type: 'danger', message: '取消支付' });
            },
          });
        });
      }).catch(()=>{
        this.getComboList()
        this.loading = false;
      });;
    },
  },
};
</script>
