<template>
  <div>
    <van-row class="amount">
      <van-col span="12" class="amount-first">
        <span>余额(元)</span><br />
        <span style="font-size: 2rem">{{ balance }}</span>
      </van-col>
      <van-col span="12" class="amount-two">
        <span style="white-space: nowrap; margin-left: -17px"
          >{{ msisdn }}({{ operator }})</span
        ><br />
        <!-- <span style="font-size: 0.7rem; color: #b2b2b2">首充享优惠</span><br /> -->
        <br />
        <span @click="goToCobmo">{{ goTitle }}&nbsp;></span>
      </van-col>
    </van-row>
    <van-row class="detail">
      <van-col span="8" style="padding-right: 3rem">
        <span>{{ nowMonth }}月</span><br />
        <span>账单</span>
      </van-col>
      <van-col span="8" @click="toUrl('voiceAdd')">
        <span>{{ residueVoice }}</span
        ><br />
        <span>剩余语音</span>
      </van-col>
      <van-col span="8" style="padding-left: 3rem" @click="toUrl('flowAdd')">
        <span>{{ residueFlow }}</span
        ><br />
        <span>剩余流量</span>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { selectCardUsed } from "@/api/card";
import { parseTime } from "@/utils/index";
import { calculateFlow } from "@/utils/convert";
import { Dialog,Notify } from "vant";
import Cookies from "js-cookie";
export default {
  name: "Head",
  data() {
    return {
      goTitle: "充值余额",
      goName: "balance",
      msisdn: "",
      iccId: "",
      balance: 0,
      residueFlow: "0M",
      residueVoice: "0分",
      nowMonth: parseTime(new Date(), "{m}"),
      operator: "中国移动",
      expiryDate: "",
    };
  },
  props:["search"],
  watch: {
    search(newVal, oldVal) {
      console.log('=-=-=-=-=-=-=-=',newVal,oldVal)
      this.getCardInfo();
      this.getCardUsed();
      Dialog.alert({
        title: "测试",
        message: "流量刷新",
      });
    },
  },
  mounted() {
    const routerPath = this.$route.name;
    if (routerPath == "balance") {
      this.goTitle = "充值套餐";
      this.goName = "combo";
    }
  },
  created() {
    this.getCardInfo();
    this.getCardUsed();
  },
  methods: {
    toUrl(rout){
      console.log(rout)
      // this.$router.push("/"+rout+"/wx996229f81e6a88f8")
    },
    getCardInfo() {
      console.log('===========================info')
      let wid = localStorage.getItem('resFlog1')
      let user = JSON.parse(localStorage.getItem('user-info'))
      let iccid = Cookies.get('card-id');
      this.$store
        .dispatch("getCardInfo", {
          iccid: iccid || user.iccid,
          officialId: wid,
        })
        .then((res) => {
          console.log(res);
          this.msisdn = res.msisdn;
          this.iccId = res.iccid;
          this.balance = res.balance;
          this.operator =
            res.corp === 1
              ? "中国移动"
              : res.corp === 2
              ? "中国联通"
              : "中国电信";
        });
    },
    goToCobmo() {
      let appId = localStorage.getItem('resFlog1')
      this.$router.push({
        name: this.goName,
        params: { officialId: appId },
      });
    },
    getCardUsed() {
      console.log('===========================used')
      let iccid = Cookies.get('card-id');
      selectCardUsed(
        iccid,
        // parseTime(new Date(), "{y}{m}")
      ).then((res) => {
        console.log(res, "用户信息");
        if (res != null) {
          let cardUsed = calculateFlow(res);
          console.log(cardUsed)
          this.residueFlow = cardUsed.residueFlowUnit;
          this.residueVoice = cardUsed.residueVoiceUnit;
          // this.nowMonth = String(res.cycleYm).slice(-2);
          this.nowMonth = (new Date()).getMonth()+1;
          this.expiryDate = cardUsed.expiryDate;
        }
      });
    },
  },
};
</script>

<style scoped></style>
